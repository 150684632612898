import { connect } from 'react-redux'
import SuperSort from './SuperSort.jsx'
import { getSortOptions, getSort, getFilters } from '../../../selectors/search-filter-selectors.js'
import { getURLParameters } from '../../../../shared/utility-functions/searchParams.js'
import { getSchedulingType } from '../../../../shared/selectors/shared-scheduling-selectors.js'
import { insertBrowserHistory } from '../../../state/history.js'

const mapStateToProps = (state, ownProps) => ({
  filteredProviderCount: ownProps.filteredProviderCount,
  searchLocation: state.searchInput.searchLocation.address ? state.searchInput.searchLocation.address : null,
  flow: getSchedulingType(state),
  currentValue: getSchedulingType(state) === "mam" ? 'A TO Z' : getSort(state),
  options:  getSortOptions(state),
  filters: getFilters(state),
  scheduleOverriden: state.doctorSearch.overrideScheduling,
  enableUrl: ownProps.enableUrl,
  fadSearchedBounds: (state.fadMap && state.fadMap.searchedBounds),
  selectedLocation: ownProps.selectedLocation,
  setSelectedLocation: ownProps.setSelectedLocation,
  providersAtLocationCount: ownProps.providersAtLocationCount,
  mapMarkersFAD: ownProps.mapMarkersFAD,
  selectedProvider: ownProps.selectedProvider,
  locationsAtSelectedProvider: ownProps.locationsAtSelectedProvider,
  setSelectedProvider: ownProps.setSelectedProvider,
  toggleLocationList: ownProps.toggleLocationList,
  isLocationListLoading: ownProps.isLocationListLoading,
  showHiddenMarkers: ownProps.showHiddenMarkers
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    selectOptionHandler: (type, sortOrder) => dispatch({
        type: type,
        value: sortOrder
      }),
      turnOnOverride: () => dispatch({
        type: 'TURN_ON_ONLINE_SCHEDULING'
      }),
      turnOffOverride: () => dispatch({
        type: 'TURN_OFF_ONLINE_SCHEDULING'
      })
})

const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    selectOptionHandler: (sortOrder) => {
      if (stateProps.disabled) return
      let type = 'SET_DOCTOR_SEARCH_SORT_ORDER'
      let urlParams = getURLParameters()
      if (sortOrder === 'First Available' && stateProps.flow !== 'sad') {
        let exists = stateProps.filters.selectedAvailability.find(f => f === 'Doctors with Online Scheduling')
        if (!exists) {
          dispatchProps.turnOnOverride()
        }
        urlParams.filters.selectedAvailability = stateProps.filters.selectedAvailability
        let found = urlParams.filters.selectedAvailability.find(f => f === 'Doctors with Online Scheduling')
        if (!found) {
          urlParams.filters.selectedAvailability.push('Doctors with Online Scheduling')
        }
      } else if (stateProps.scheduleOverriden) {
        dispatchProps.turnOffOverride()
      }
      dispatchProps.selectOptionHandler(type, sortOrder)
      if (stateProps.enableUrl) {
        insertBrowserHistory({ ...urlParams, sort: sortOrder })
      }
    }
  })

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SuperSort)