import React from "react";
import SVGInline from 'react-svg-inline'
import "../SuperResultsCard.scss";
import "./SuperResultsMamCard.scss";
import { cleanNumber } from "../../../../../shared/utility-functions/index.js";
import { clickToCallEvent } from "../../../../../shared/utility-functions/googleTagManager.js";
import { SuperResultsMamTimes } from "./SuperResultsMamTimes/SuperResultsMamTimes.jsx";
import calendar from '../../../../../shared/assets/calendar-blue.svg'
export const SuperResultsMamCard = (props) => {
  const { location, flow } = props;

  const clickToCall = () => {
    const dataLayerObject = {
      flow: flow,
      PhoneNumber: location.Phone,
      Name: location.Name,
      ID: location.Id,
      Specialty: "Memmography",
      Address: location.Address ? `${location.Address}, ${location.City} ${location.State} ${location.ZipCode}` : undefined,
    };

    clickToCallEvent(dataLayerObject);
  };
  return (
    <div className='super-results-card--container '>
      <div className='super-results-profile--container'>
        <div className='profile-image--container'>
          <div className='profile-image--url' style='cursor: unset;'>
            <img
              alt={location.Name}
              src={location.LandscapePhotoURL || "../../../../../shared/assets/no-location.svg"}
              aria-label={location.Name}
              width='120'
            />
          </div>
        </div>
        <div className='profile-details--container' >
          <div className='title-profile-url' style='cursor: unset;'>
            <div className='title'>{location.Name}</div>
          </div>
          <a
            href={`https://maps.apple.com/?q=${location.Address}+${location.City}+${location.State}`}
            target='_blank'
            className='profile-address'>
            {location.Distance ? location.Distance + ' mi - ' : ''}{location.Address + ', ' + location.City}
          </a>
          <div className='phone-number '>
            <a
              href={`tel:${cleanNumber(location.Phone)}`}
              onClick={() => clickToCall()}>
              <span>{location.Phone}</span>
            </a>
          </div>

          {(location.OpenSat || location.OpenSun) && (
            <span className='weekend-availability'>
              <SVGInline svg={calendar} /> Weekend Availability
            </span>
          )}
        </div>
      </div>
      <SuperResultsMamTimes
        location={location}
        flow={flow}
      />
    </div>
  );
};
