import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import multi from 'redux-multi'
import providerDistanceSaga from '../sagas/provider-distance-saga'
import timeoutSaga from '../sagas/timeout-saga'
import rootReducer from '../state/root-reducer'
import searchSaga from '../sagas/mam-search-saga.js'
import autocompleteSaga from '../sagas/fetch-autocomplete-data-saga'
import fetchLocationFilterAppointmentSaga from '../sagas/fetch-location-filtering-appointments-saga'
import locationFilterChangedSaga from '../sagas/location-date-filter-changed-saga'
import locationSearchSaga from '../sagas/location-search-saga'
import FindALocationConnector from './FindALocationConnector.jsx'
import fetchLocationAutocompleteDataSaga from '../sagas/fetch-locations-autocomplete-data-saga'
import './fal-root.scss'
import mamSearchSaga from '../sagas/mam-search-saga.js'

export default class FALRoot extends React.Component {
  constructor (props) {
    super(props)
    const sagaMiddleware = createSagaMiddleware()
    this.sasStore = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))

    sagaMiddleware.run(providerDistanceSaga)
    sagaMiddleware.run(timeoutSaga)
    sagaMiddleware.run(searchSaga)
    sagaMiddleware.run(locationSearchSaga)
    sagaMiddleware.run(mamSearchSaga)
    sagaMiddleware.run(autocompleteSaga)
    sagaMiddleware.run(fetchLocationFilterAppointmentSaga)
    sagaMiddleware.run(fetchLocationAutocompleteDataSaga)
    sagaMiddleware.run(locationFilterChangedSaga)
  }

  render () {
    return (
      <Provider store={this.sasStore}>
        <div>
          <FindALocationConnector displayType={this.props.displayType} searchOptions={this.props.searchOptions} headline={this.props.headline} flow={this.props.searchOptions.flowType} filterOptions={this.props.filterOptions} questionnaireCompleted={this.props.questionnaireCompleted} />
        </div>
      </Provider>
    )
  }
}
