import React from 'react'
import './search.scss'
import LocationInput from './LocationInput/LocationInput.jsx'
import LocationWithin from './LocationWithin/LocationWithin.jsx'
import LookingForConnector, { LookingForLocationsConnector, LookingForServicesConnector } from './LookingFor'
import SVGInline from 'react-svg-inline'
import MagnifyingGlass from '../../../shared/assets/MagnifyingGlass.svg'
import ChevronDown from '../../../shared/assets/chevron-down.svg'
import { insertBrowserHistory } from '../../state/history'
import searchCount from '../../../shared/data-mappers/count-string.js'
import { scrollToLocation, getURLParameters, togglePreventScroll } from '../../../shared/utility-functions'
import Modal from '../../../shared/components/Modal/Modal.jsx'
import searchUrl from '../../../shared/utility-functions/searchUrl.js'

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.searchCalls = 0
    this.state = {
      searched: false,
      lookingFor: props.lookingFor ? props.lookingFor : '',
      address: props.address ? props.address : null,
      latitude: props.latitude ? props.latitude : null,
      longitude: props.longitude ? props.longitude : null,
      within: props.within ? props.within : 25,
      mobileCollapsed: false,
      showModal: false
    }
    this.setLocation = this.setLocation.bind(this)
    this.setLookingFor = this.setLookingFor.bind(this)
    this.setWithin = this.setWithin.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.applyClicked = this.applyClicked.bind(this)
    this.enterSubmit = this.enterSubmit.bind(this)
    this.onSearchClick = this.onSearchClick.bind(this)
    this.closeModal = this.closeModal.bind(this)

  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.searchParams) !== JSON.stringify(prevProps.searchParams)) {
      this.setState({
        lookingFor: this.props.searchParams.q ? this.props.searchParams.q : '',
        address: this.props.searchParams.locq ? this.props.searchParams.locq : null,
        latitude: this.props.searchParams.lat ? this.props.searchParams.lat : null,
        longitude: this.props.searchParams.lng ? this.props.searchParams.lng : null,
        within: this.props.searchParams.dist ? this.props.searchParams.dist : 25
      })
      this.props.runSearch(this.props.searchParams)
    }

    if (this.props.flow === 'fad' && this.props.searchOptions.isSuperResults && this.props.fadSearchedBounds && this.props.fadSearchedBounds !== prevProps.fadSearchedBounds && this.state.address !== null ) {
      this.setState({
        address: null,
        latitude: null,
        longitude: null,
        within: null
      })
    }

    if (this.props.dataLoaded && this.props.numberOfSearchItems === 0 && !prevProps.dataLoaded) {
      this.setState({ mobileCollapsed: false })
    }
  }



  setLookingFor(lookingFor) {
    this.setState({ lookingFor: lookingFor })
  }

  setLocation(value, lat, lng) {
    if (!value) {
      lat = null
      lng = null
    }
    let modifiedValue = value
    if (value) modifiedValue = value.replace(", USA", "")
    this.setState({ address: modifiedValue, latitude: lat, longitude: lng })
  }

  setWithin(value) {
    this.setState({ within: parseInt(value) })
  }

  applyClicked() {
    if (this.state.address && this.state.address !== '' && (!this.state.latitude || !this.state.longitude)) {
      this.searchCalls += 1
      if (this.searchCalls < 20) {
        setTimeout(() => {
          this.applyClicked()
        }, 100)
        return
      } else {
        this.searchCalls = 0
      }
    } else {
      this.searchCalls = 0
    }

    let searchObject = {}
    searchObject.lat = this.state.latitude
    searchObject.lng = this.state.longitude

    if (this.props.flow === 'mam') {
      searchObject.VisitTypeId = this.props.visitTypeId
      searchObject.searchRadius = this.state.within
      searchObject.locq = this.state.address
    } else if (this.props.flow === 'service') {
      searchObject.q = this.state.lookingFor
    } else if (this.props.flow === 'fad') {
      searchObject.q = this.state.lookingFor
      searchObject.locq = this.state.address
      if (searchObject.locq || searchObject.lat || searchObject.lng) {
        // searchObject.dist = this.props.searchLocation.within ? this.props.searchLocation.within : 5
        searchObject.dist = 5
      }
      // console.log('searchObject in SearchBar.jsx within fad flow -> ', searchObject)
    }
    else {
      searchObject.q = this.state.lookingFor
      searchObject.locq = this.state.address
      if (searchObject.locq || searchObject.lat || searchObject.lng) {
        searchObject.dist = this.state.within
      }
    }

    if (this.props.searchOptions && this.props.searchOptions.enableUrl) {
      if (searchObject.q && searchObject.q.indexOf('&') > -1) searchObject.q = searchObject.q.replace('&', encodeURIComponent('&'))
      insertBrowserHistory({ search: searchObject, filters: getURLParameters().filters, sort: getURLParameters().sort })
    }
    this.props.runSearch(searchObject)
  }

  toggleCollapse() {
    let currentState = this.state.mobileCollapsed
    this.setState({ mobileCollapsed: !currentState })
  }

  enterSubmit(event) {
    if (event.keyCode === 13 && (!!this.state.lookingFor || !!this.state.address)) {
      this.applyClicked()
      scrollToLocation('#filter-button-row-header')
    }
  }

  getMobileDropdownType() {
    if (!this.props.searchOptions.showLookingFor && this.props.searchOptions.showLocation) {
      return ' sad'
    } else if (this.props.searchOptions.showLookingFor && !this.props.searchOptions.showLocation) {
      return ' lookingfor'
    } else {
      return ''
    }
  }

  renderResultsCount() {
    if (this.props.searchOptions) {
      if (this.props.searchOptions.showDoctorCount && (this.props.searchOptions.showLookingFor || this.props.searchOptions.showLocation)) {
        return <div className='count-search-label'>
          <div className='doctor-count-label'>{this.props.dataLoaded && !this.props.filtersLoading ? this.props.numberOfSearchItems : '__'}</div>
          {searchCount(this.props.numberOfSearchItems, this.props.flow, { affiliated: this.props.showAffiliatedDoctors, isBlock: this.props.searchOptions.isBlock, app: this.props.app })} Found
        </div>
      } else if (!this.props.searchOptions.showDoctorCount) {
        return <div className='count-search-label'>{!this.props.searchOptions.showLookingFor && this.props.searchOptions.showLocation ? "I'm Looking" : ''}</div>
      }
    }
    return null
  }

  renderResultsCountMobile() {
    if (this.props.searchOptions && this.props.searchOptions.showDoctorCount) {
      return (
        <div className='doctors-found-mobile'>
          {this.props.dataLoaded && !this.props.filtersLoading ? this.props.numberOfSearchItems : '__'}&nbsp;
          {searchCount(this.props.numberOfSearchItems, this.props.flow, { affiliated: this.props.showAffiliatedDoctors, isBlock: this.props.searchOptions.isBlock, app: this.props.app })} Found
        </div>
      )
    }
    return <div className='doctors-found-mobile'>Search</div>
  }

  handleSearch() {
    this.applyClicked()
    scrollToLocation('#filter-button-row-header')

    if (this.state.showModal) this.closeModal()
  }

  onSearchClick() {
    togglePreventScroll(true)
    this.setState({ showModal: true })
  }

  closeModal() {
    togglePreventScroll(false)
    this.setState({ showModal: false })
  }

  render() {
    let collapseStyle, collapseIcon, collapseTitleStyle
    if (this.state.mobileCollapsed) {
      collapseTitleStyle = 'collapse-title closed'
      collapseStyle = 'mobile-collapsed'
      collapseIcon = <SVGInline className='toggle-down' svg={ChevronDown} />
    } else {
      collapseTitleStyle = 'collapse-title'
      collapseStyle = 'mobile-open' + this.getMobileDropdownType()
      collapseIcon = <SVGInline className='toggle-up' svg={ChevronDown} />
    }

    let showHideSearchClass = this.props.searchOptions && !this.props.searchOptions.showLookingFor && !this.props.searchOptions.showLocation ? 'display-none' : ''
    let showHideLookingForClass = this.props.searchOptions.showLookingFor ? 'looking-for-container' : 'looking-for-container display-none'
    let showHideLocationClass = 'near-within-container'
    let showHideLocationMobileClass = 'near-mobile-container'
    let showHideLocationWithinMobileClass = 'within-mobile-container'
    let showHideSearchButton = 'search-button-container'
    let showHideSearchButtonMobile = 'search-mobile-container'

    if (!this.props.searchOptions.showLocation) {
      showHideLocationClass = 'near-within-container display-none'
      showHideLocationMobileClass = 'near-mobile-container display-none'
      showHideLocationWithinMobileClass = 'within-mobile-container display-none'
    }

    return (
      <div className={showHideSearchClass}>
        {/* DESKTOP VERSION */}
        {
          this.props.flow === 'mam' &&
          <div className='super-search-container' style={{ 'justify-content':'flex-start'}}>
            <div className='input-container'>
              <div id='doctor_search_near_input' className='search-label'>
                <span className='search-row-mammo--text'>Search by Location <SVGInline svg={MagnifyingGlass} className='icon' /></span>
              </div> 
              <LocationInput enterSubmit={this.enterSubmit} address={this.state.address} latitude={this.state.latitude} longitude={this.state.longitude} within={this.state.within} setLocation={this.setLocation} />
            </div>
            <button className='btn apply-button fad-search-button desktop' onClick={() => this.handleSearch()} >Search</button>
          </div>
        }
        {
          this.props.flow === 'fad' && this.props.searchOptions.isSuperResults && //Super Results
          <div className='super-search-container'>
            <LookingForConnector flow={this.props.flow} enterSubmit={this.enterSubmit} setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} />
            <LocationInput enterSubmit={this.enterSubmit} address={this.state.address} latitude={this.state.latitude} longitude={this.state.longitude} within={this.state.within} setLocation={this.setLocation} />
            {/* <LocationWithin enterSubmit={this.enterSubmit} address={this.state.address} setWithin={this.setWithin} within={this.state.within} /> */}
            <button className='btn apply-button fad-search-button desktop' onClick={() => this.handleSearch()} >Search</button>
          </div>
        }
        {
          (this.props.flow !== 'fad' || (this.props.flow === 'fad' && !this.props.searchOptions.isSuperResults)) && this.props.flow !== 'mam' &&
          <div id='doctor-search-container' className='search-container'>
            {this.renderResultsCount()}
            <div className={showHideLookingForClass}>
              <div id='doctor_search_for_input' className='search-label'>For</div>
              {(this.props.flow === 'sad' || this.props.flow === 'fad' || this.props.flow === 'cin') && <LookingForConnector flow={this.props.flow} enterSubmit={this.enterSubmit} setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} />}
              {this.props.flow === 'location' && <LookingForLocationsConnector enterSubmit={this.enterSubmit} setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} />}
              {this.props.flow === 'service' && <LookingForServicesConnector enterSubmit={this.enterSubmit} setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} />}
            </div>
            <div className={showHideLocationClass}>
              <div className='input-container'>
                <div id='doctor_search_near_input' className='search-label'>Near</div>
                <LocationInput enterSubmit={this.enterSubmit} address={this.state.address} latitude={this.state.latitude} longitude={this.state.longitude} within={this.state.within} setLocation={this.setLocation} />
              </div>
              <div className='input-container'>
                <div className='search-label'>Within</div>
                <LocationWithin enterSubmit={this.enterSubmit} address={this.state.address} setWithin={this.setWithin} within={this.state.within} />
              </div>
            </div>
            <div className={showHideSearchButton}>
              <button className='btn apply-button fad-search-button desktop' onClick={() => this.handleSearch()} >Search</button>
            </div>
          </div>
        }

        {/* MOBILE VERSION */}
        {
          this.props.flow === 'fad' && this.props.searchOptions.isSuperResults && //Super Results
          <div className='super-search-container-mobile'>
            <div className='mobile-search-bar' onClick={() => this.onSearchClick()}>
              <div className='search-bar-text-container'>
                {
                  this.state.lookingFor && this.state.address &&
                  <React.Fragment>
                    <span className='primary-text'>{this.state.lookingFor}</span>
                    <span className='secondary-text'>{this.state.address}</span>
                  </React.Fragment>
                }
                {
                  this.state.lookingFor && !this.state.address &&
                  <span className='main-text'>{this.state.lookingFor}</span>
                }
                {
                  !this.state.lookingFor && this.state.address &&
                  <span className='main-text'>{this.state.address}</span>
                }
              </div>
              <SVGInline className='super-search-icon' svg={MagnifyingGlass} />
            </div>
            {/* <LocationWithin enterSubmit={this.enterSubmit} address={this.state.address} setWithin={this.setWithin} within={this.state.within} /> */}
            <Modal hideFunction={this.closeModal} showModal={this.state.showModal}>
              <div className='super-modal-container'>
                <h4>Search</h4>
                <LookingForConnector flow={this.props.flow} enterSubmit={this.enterSubmit} setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} />
                <LocationInput enterSubmit={this.enterSubmit} address={this.state.address} latitude={this.state.latitude} longitude={this.state.longitude} within={this.state.within} setLocation={this.setLocation} />
                <div className='modal-popular-searches'>
                  <h4>Popular Searches</h4>
                  <ul>
                    <li><a target='_self' href={searchUrl('Primary Care')}>Primary Care</a></li>
                    <li><a target='_self' href={searchUrl('Cardiologist')}>Cardiologist</a></li>
                    <li><a target='_self' href={searchUrl('Family Medicine')}>Family Medicine</a></li>
                    <li><a target='_self' href={searchUrl('Obstetrics/Gynecology')}>Obstetrics/Gynecology</a></li>
                    <li><a target='_self' href={searchUrl('Orthopedics')}>Orthopedics</a></li>
                  </ul>
                </div>
                <div className='super-modal-footer'>
                  <span onClick={this.closeModal}>Cancel</span>
                  <button className='btn apply-button fad-search-button desktop' onClick={() => this.handleSearch()} >Search</button>
                </div>
              </div>
            </Modal>
          </div>
        }
        {
          this.props.flow !== 'fad' &&
          <div className={`search-container-mobile ${this.props.flow === 'sad' ? 'sad' : ''}`}>
            <div className={collapseTitleStyle} onClick={() => this.toggleCollapse()} >
              {this.renderResultsCountMobile()}
              {collapseIcon}
            </div>
            <div className={collapseStyle}>
              <div>
                {
                  <div className={showHideLookingForClass}>
                    <div className='search-label'>I'm Looking For</div>
                    {(this.props.flow === 'sad' || this.props.flow === 'fad' || this.props.flow === 'cin') && <LookingForConnector flow={this.props.flow} mobile enterSubmit={this.enterSubmit} setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} />}
                    {this.props.flow === 'location' && <LookingForLocationsConnector mobile enterSubmit={this.enterSubmit} setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} />}
                    {this.props.flow === 'service' && <LookingForServicesConnector mobile enterSubmit={this.enterSubmit} setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} />}
                  </div>
                }
                <div className={showHideLocationMobileClass}>
                  <div className='search-label'>Near</div>
                  <LocationInput mobile enterSubmit={this.enterSubmit} address={this.state.address} latitude={this.state.latitude} longitude={this.state.longitude} within={this.state.within} setLocation={this.setLocation} />
                </div>
                <div className={showHideLocationWithinMobileClass}>
                  <div className='search-label'>Within</div>
                  <LocationWithin mobile address={this.state.address} setWithin={this.setWithin} within={this.state.within} />
                </div>
                <div className={showHideSearchButtonMobile}>
                  <button className='btn apply-button fad-search-button mobile' onClick={() => this.handleSearch()} >Search</button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
