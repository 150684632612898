import React from 'react'
import SVGInline from 'react-svg-inline'
import filterIcon from '../../../../shared/assets/filter-icon.svg'
import SuperFilterConnector from '../SuperFilter/SuperFilterConnector.jsx'
import { getSpecialtiesFromAllDoctors, getAvailabilityFromAllDoctors, getDoctorTypeFromAllDoctors, getGendersFromAllDoctors, getLanguagesFromAllDoctors, getHospitalFromAllDoctors, getAffiliationFromAllDoctors, getScheduleFromAllDoctors, getAcceptingFromAllDoctors, getProvidersWithRatings } from '../../../selectors/doctor-search-filter-selectors'
import { getWeekendHours } from '../../../selectors/location-search-filter-selectors'
import { insertEmptyFilters, getURLParameters } from '../../../../shared/utility-functions'
import { insertBrowserHistory } from '../../../state/history'
import './FiltersRow.scss'

const FiltersRow = ({ selectedProviderOrLoading, selectedFiltersCount, searchOptions, resetFilters, filterOptions, filteredProviderCount }) => {
  const clearAllFilters = (e) => {
    e.stopPropagation()
    if (searchOptions && searchOptions.enableUrl) {
      insertBrowserHistory(insertEmptyFilters(getURLParameters()))
    }
    resetFilters()
  }

  const createFilters = () => {
    let filters = []

    if (selectedProviderOrLoading) return filters

    if (searchOptions.flowType === 'mam') { 
      filters.push(<SuperFilterConnector key={1} searchOptions={searchOptions} title='Availability' getOption={getWeekendHours} filterKey='selectedWeekendHours' dispatchType='SET_SELECTED_WEEKEND_HOURS' />)
      return filters
    }

    if (filterOptions.showAcceptingNewScheduling) {
      let options = getAvailabilityFromAllDoctors
      if (filterOptions.hideAccepting) {
        options = getScheduleFromAllDoctors
      } else if (filterOptions.hideSchedulingFilter) {
        options = getAcceptingFromAllDoctors
      }
      filters.push(<SuperFilterConnector key={1} searchOptions={searchOptions} title='Availability' getOption={options} filterKey='selectedAvailability' dispatchType='SET_SELECTED_AVAILABILITY' />)
    }

    filters.push(<SuperFilterConnector key={2} searchOptions={searchOptions} title='Rating' getOption={getProvidersWithRatings} filterKey='selectedRating' dispatchType='SET_SELECTED_RATING' filteredProviderCount={filteredProviderCount} />)
    if (filterOptions.showSpeciality) filters.push(<SuperFilterConnector key={3} searchOptions={searchOptions} title='Specialty' getOption={getSpecialtiesFromAllDoctors} filterKey='selectedSpecialty' dispatchType='SET_SELECTED_SPECIALTY' filteredProviderCount={filteredProviderCount} />)
    if (filterOptions.showDoctorType) filters.push(<SuperFilterConnector key={4} searchOptions={searchOptions} title='Doctor Type' getOption={getDoctorTypeFromAllDoctors} filterKey='selectedDoctorType' dispatchType='SET_SELECTED_DOCTOR_TYPE' filteredProviderCount={filteredProviderCount} />)
    if (filterOptions.showGenderPreference) filters.push(<SuperFilterConnector key={5} searchOptions={searchOptions} title='Gender' getOption={getGendersFromAllDoctors} filterKey='selectedGender' dispatchType='SET_SELECTED_GENDER' filteredProviderCount={filteredProviderCount} />)
    if (filterOptions.showAdditionalLanguages) filters.push(<SuperFilterConnector key={6} searchOptions={searchOptions} title='Language' getOption={getLanguagesFromAllDoctors} filterKey='selectedLanguage' dispatchType='SET_SELECTED_LANGUAGE' filteredProviderCount={filteredProviderCount} />)
    if (filterOptions.showHospital) filters.push(<SuperFilterConnector key={7} searchOptions={searchOptions} title='Hospital' getOption={getHospitalFromAllDoctors} filterKey='selectedHospital' dispatchType='SET_SELECTED_HOSPITAL' filteredProviderCount={filteredProviderCount} />)
    if (filterOptions.showAffiliation) filters.push(<SuperFilterConnector key={8} searchOptions={searchOptions} title='Affiliation' getOption={getAffiliationFromAllDoctors} filterKey='selectedAffiliation' dispatchType='SET_SELECTED_AFFILIATION' filteredProviderCount={filteredProviderCount} />)
    return filters
  }

  return (
    <div className={`filters-row--container ${searchOptions.flowType !== 'mam' ? 'filters-row-withoutmam' : ''} only-desktop `}>
      <span className='filters-row--text'>Filters <SVGInline className='filter-icon' svg={filterIcon} /></span>
      <div className='filters-row--pills'>
        {createFilters()}
      </div>
      {selectedFiltersCount > 0 && !selectedProviderOrLoading
        ? <span className='filters-row--clear' onClick={(e) => { clearAllFilters(e) }}>Clear Filters</span>
        : searchOptions.flowType === 'mam' ?
          ''
          : <span className='filters-row--clear disabled'>Clear Filters</span>
      }
    </div>
  )
}

export default FiltersRow
