import React, { Component } from "react";
import "./InfiniteScroll.scss";
import SuperResultsCardConnector from "../SearchCards/SuperResultsCard/SuperResultsCardConnector.jsx";
import SuperLocationCard from "../SearchCards/SuperResultsCard/SuperLocationCard.jsx";
import SuperLocationCardAltConnector from "../SearchCards/SuperResultsCard/SuperLocationCardAltConnector.jsx";
import LoadingElement from "../../../shared/components/LoadingElement/LoadingElement.jsx";
import { SortOrder } from "../../../shared/utility-functions/sortOrder.js";
import { isOBGYNInt } from "../../../shared/utility-functions/isOBGYN";
import { isNullOrUndefined } from "../../../shared/utility-functions/objectUtil";
import { SuperResultsMamCard } from "../SearchCards/SuperResultsCard/SuperResultsMamCard/SuperResultsMamCard.jsx";

export default class InfiniteScroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      start: 0,
      end: this.props.pageSize,
      hasMore: this.props.results.length > this.props.pageSize,
      totalPages: this.props.results ? Math.ceil(this.props.results.length / this.props.pageSize) : 1,
    };

    this.resetPage = this.resetPage.bind(this);
    this.handleLazyLoad = this.handleLazyLoad.bind(this);
    this.handleLazyLoadAlt = this.handleLazyLoadAlt.bind(this);
    this.addPage = this.addPage.bind(this);
    this.sortByObGyn = this.sortByObGyn.bind(this);
    this.isLocationClickedLocation = this.isLocationClickedLocation.bind(this);
    this.infiniteScrollAvailableAppointmentSortOrder = SortOrder.ASC;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.currentPage !== nextState.currentPage) {
      return true;
    }

    if (this.props.results !== nextProps.results) {
      return true;
    }

    if (this.props.preliminaryStatus !== nextProps.preliminaryStatus) {
      return true;
    }

    if (this.props.selectedLocation !== nextProps.selectedLocation) {
      return true;
    }

    if (this.props.providersAtLocation !== nextProps.providersAtLocation) {
      return true;
    }

    if (this.props.isLocationListLoading !== nextProps.isLocationListLoading) {
      return true;
    }

    if (
      this.props.selectedProvider !== nextProps.selectedProvider &&
      this.props.locationsAtSelectedProvider !== nextProps.locationsAtSelectedProvider
    ) {
      return true;
    }

    if (this.props.clickedProvider !== nextProps.clickedProvider) {
      return true;
    }

    if (this.props.clickedLocation !== nextProps.clickedLocation) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleLazyLoad);
    if (this.props.mapScrollRef.current) this.props.mapScrollRef.current.addEventListener("scroll", this.handleLazyLoadAlt);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleLazyLoad);
    if (this.props.mapScrollRef.current) this.props.mapScrollRef.current.removeEventListener("scroll", this.handleLazyLoadAlt);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.results) !== JSON.stringify(prevProps.results)) {
      //Fire on results change
      this.resetPage();
    }
  }

  resetPage() {
    this.props.setPageNumber(1);
    this.setState({
      page: 1,
      start: 0,
      end: this.props.pageSize,
      hasMore: this.props.results.length > this.props.pageSize,
      totalPages: this.props.results ? Math.ceil(this.props.results.length / this.props.pageSize) : 1,
    });
  }

  addPage() {
    let hasMore = true;
    let start = 0;
    let nextPageNumber = this.state.currentPage + 1;
    let end = start + this.props.pageSize * nextPageNumber;

    if (end >= this.props.results.length) {
      hasMore = false;
      end = this.props.results.length;
    }

    this.setState({
      currentPage: nextPageNumber,
      hasMore,
      start,
      end,
      totalPages: this.props.results ? Math.ceil(this.props.results.length / this.props.pageSize) : 1,
    });

    this.props.setPageNumber(nextPageNumber);
  }

  handleLazyLoad() {
    let bufferNumber = 200;
    let footerTop = document.getElementsByClassName("footer")[0] ? document.getElementsByClassName("footer")[0].getBoundingClientRect().top : null;
    if (footerTop - bufferNumber < window.innerHeight && this.state.end < this.props.results.length) {
      this.addPage();
    }
  }

  handleLazyLoadAlt(event) {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    const threshold = 1000;
    if (scrollHeight - scrollTop - clientHeight <= threshold && this.state.end < this.props.results.length) {
      this.addPage();
    }
  }

  sortByObGyn(a, b) {
    const sortOrderValue = isOBGYNInt(a.specialtyIds) - isOBGYNInt(b.specialtyIds);

    return this.infiniteScrollAvailableAppointmentSortOrder === SortOrder.ASC ? sortOrderValue : sortOrderValue * -1;
  }

  isLocationClickedLocation(location) {
    if (!this.props.clickedLocation || !location) {
      return false;
    }
    const clickedKey = this.props.clickedLocation.AddressKey ? this.props.clickedLocation.AddressKey : this.props.clickedLocation.id;
    const locationKey = location.AddressKey ? location.AddressKey : location.id;
    if (clickedKey === locationKey) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div
        className='infinite-scroll--container'
        ref={this.props.listRef}
        onScroll={(event) => {
          this.props.updateScrollPosition();
          this.handleLazyLoadAlt(event);
        }}>
        {this.props.flow == "mam" ? (
          this.props.results.slice(this.state.start, this.state.end).map((location, index) => {
            return <SuperResultsMamCard
            location={location}
            flow={this.props.flow}
            key={`mam-locaiton-${index}`}
            />;
          })
        ) : (
          <React.Fragment>
            {!this.props.selectedLocation &&
            this.props.results.length > 0 &&
            !this.props.selectedProvider &&
            !this.props.isLocationListLoading &&
            this.props.sort === "First Available" &&
            isNullOrUndefined(this.props.preliminaryStatus)
              ? this.props.results
                  .slice(this.state.start, this.state.end)
                  .sort(this.sortByObGyn)
                  .map((provider, index) => {
                    return (
                      <SuperResultsCardConnector
                        id={`provider-card-${provider.npi}`}
                        options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
                        provider={provider}
                        key={index}
                        preliminaryStatus={this.props.preliminaryStatus}
                        setPreliminaryStatus={this.props.setPreliminaryStatus}
                        active={this.props.clickedProvider && this.props.clickedProvider.npi === provider.npi}
                        onMouseEnter={this.props.onMouseEnter}
                        onMouseLeave={this.props.onMouseLeave}
                        onClickProv={this.props.onClickProv}
                        setSelectedLocation={this.props.setSelectedLocation}
                        setSelectedProvider={this.props.setSelectedProvider}
                        results={this.props.results}
                        setIsLocationListLoading={this.props.setIsLocationListLoading}
                      />
                    );
                  })
              : !this.props.selectedLocation && this.props.results.length > 0 && !this.props.selectedProvider && !this.props.isLocationListLoading
              ? this.props.results.slice(this.state.start, this.state.end).map((provider, index) => {
                  return (
                    <SuperResultsCardConnector
                      id={`provider-card-${provider.npi}`}
                      options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
                      provider={provider}
                      key={index}
                      preliminaryStatus={this.props.preliminaryStatus}
                      setPreliminaryStatus={this.props.setPreliminaryStatus}
                      active={this.props.clickedProvider && this.props.clickedProvider.npi === provider.npi}
                      onMouseEnter={this.props.onMouseEnter}
                      onMouseLeave={this.props.onMouseLeave}
                      onClickProv={this.props.onClickProv}
                      setSelectedLocation={this.props.setSelectedLocation}
                      setSelectedProvider={this.props.setSelectedProvider}
                      results={this.props.results}
                      setIsLocationListLoading={this.props.setIsLocationListLoading}
                    />
                  );
                })
              : null}

            {this.props.selectedProvider && this.props.locationsAtSelectedProvider && (
              <SuperResultsCardConnector
                id={`provider-card-${this.props.selectedProvider.npi}`}
                options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
                provider={this.props.selectedProvider}
                preliminaryStatus={this.props.preliminaryStatus}
                setPreliminaryStatus={this.props.setPreliminaryStatus}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                setSelectedLocation={this.props.setSelectedLocation}
                setSelectedProvider={this.props.setSelectedProvider}
                results={this.props.results}
                setIsLocationListLoading={this.props.setIsLocationListLoading}
                selectedProvider={this.props.selectedProvider}
              />
            )}

            {this.props.selectedProvider &&
              this.props.locationsAtSelectedProvider &&
              this.props.locationsAtSelectedProvider.map((location, index) => {
                return (
                  <SuperLocationCardAltConnector
                    location={location}
                    selectedProvider={this.props.selectedProvider}
                    key={index}
                    updateLocationIconStyle={this.props.updateLocationIconStyle}
                    options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
                    preliminaryStatus={this.props.preliminaryStatus}
                    setPreliminaryStatus={this.props.setPreliminaryStatus}
                    active={this.isLocationClickedLocation(location)}
                    onClickLoc={this.props.onClickLoc}
                  />
                );
              })}

            {!this.props.isLocationListLoading &&
              !this.props.selectedProvider &&
              this.props.selectedLocation &&
              this.props.providersAtLocation &&
              this.props.providersAtLocation.length > 0 && (
                <SuperLocationCard
                  location={this.props.selectedLocation}
                  id={`provider-card-${
                    this.props.selectedLocation.AddressKey ? this.props.selectedLocation.AddressKey : this.props.selectedLocation.id
                  }`}
                />
              )}
            {!this.props.isLocationListLoading &&
              !this.props.selectedProvider &&
              this.props.selectedLocation &&
              this.props.providersAtLocation &&
              this.props.providersAtLocation.length > 0 &&
              this.props.providersAtLocation.map((provider, index) => {
                return (
                  <SuperResultsCardConnector
                    id={`provider-card-${provider.npi}`}
                    options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
                    provider={provider}
                    key={index}
                    preliminaryStatus={this.props.preliminaryStatus}
                    setPreliminaryStatus={this.props.setPreliminaryStatus}
                    setSelectedLocation={this.props.setSelectedLocation}
                    setSelectedProvider={this.props.setSelectedProvider}
                    results={this.props.results}
                    setIsLocationListLoading={this.props.setIsLocationListLoading}
                  />
                );
              })}

            {this.props.isLocationListLoading && (
              <div className='loader'>
                <LoadingElement />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
