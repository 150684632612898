import React from 'react'
import './fad-map.scss'
import SVGInline from 'react-svg-inline'
import Cookies from 'js-cookie'
import locIcon from '../../../shared/assets/location current.svg'
import appLocation from '../../../shared/constants/applicationLocation.js'
import { updateCookie, GeolocationAcceptOrDeny, getURLParameters } from '../../../shared/utility-functions'
import { insertBrowserHistory } from '../../state/history'
import { createValidLocationSet, defaultMarkerIconPNG, hoveredMarkerIconPNG } from './FADMapUtils'
import URLS from '../../../shared/constants/urls'
import DirectionsLink from '../../../shared/components/DirectionsLink/index'
import { getPhoneNumber } from '../../../shared/utility-functions'
import { waitForLoad } from '../../../shared/utility-functions/IE-window-load.js'
import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

export default class FADMap extends React.Component {
    constructor(props) {
        super(props)
        this.mapRef = React.createRef()
        this.center = appLocation
        this.currentOpenInfoWindow = null
        this.closeTimeoutId = null
        this.currentHoveredMarker = null
        this.currentHoveredLocation = null
        this.initMap = this.initMap.bind(this)
        this.createMapMarkers = this.createMapMarkers.bind(this)
        this.centerUserLocation = this.centerUserLocation.bind(this)
        this.searchNewArea = this.searchNewArea.bind(this)
        this.setGoogleLocationData = this.setGoogleLocationData.bind(this)
        this.createZoomButtons = this.createZoomButtons.bind(this)
        this.createDisplayRadius = this.createDisplayRadius.bind(this)
        this.addUserMarker = this.addUserMarker.bind(this)
        this.createToolTipMarkupOPG = this.createToolTipMarkupOPG.bind(this)
        this.createToolTipMarkupIndependent = this.createToolTipMarkupIndependent.bind(this)
        this.customClusterIconRenderer = this.customClusterIconRenderer.bind(this)
        this.isMarkerSelectedLocation = this.isMarkerSelectedLocation.bind(this)
        this.getSearchResults = this.getSearchResults.bind(this)
        this.state = {
            mapMarkerLocations: [],
            hoveredItemIds: null,
            displayRadius: null,
            userLatLng: null,
            searchLatLng: null,
            displayBounds: null,
            searchBounds: null,
            boundsChangeListener: null
        }
        this.markClusterListener = null
        this.dragEndListener = null
        this.zoomChangedListener = null
        this.idleListener = null
    }

    componentDidMount() {
        this.initMap()
    }

    componentWillUnmount() {
        
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.addMapListeners !== this.props.addMapListeners && this.props.addMapListeners === true) {
            let zoomChangedListener = google.maps.event.addListener(this.props.map, 'zoom_changed', () => {
                setTimeout(() => {
                    this.searchNewArea(this.props.map)
                }, 500)
            })
            google.maps.event.addListener(this.props.map, 'dragend', () => {
                setTimeout(() => {
                    this.searchNewArea(this.props.map)
                }, 500)
            })

            this.props.setZoomChangedListenerRef(zoomChangedListener)
        }
        
        if (
          (prevState.displayRadius !== this.state.displayRadius || this.state.displayRadius == null) &&
          (prevState.displayBounds !== this.state.displayBounds || prevState.displayBounds == null) &&
          (prevState.mapMarkerLocations.length !== this.state.mapMarkerLocations.length || prevState.mapMarkerLocations.length == 0)
        ) {
          if (
            (this.props.map !== null && this.props.dataLoaded && this.props.allDoctors !== prevProps.allDoctors) ||
            (this.props.searchResults !== prevProps.searchResults &&
              (this.props.sort === prevProps.sort || this.props.sort === "First Available" || prevProps.sort === "First Available"))
          ) {
            this.props.removeFADMapMarkers();
            if (this.props.markerClustererFAD !== null) {
              this.props.markerClustererFAD.clearMarkers();
              google.maps.event.removeListener(this.markClusterListener);
              this.props.setMarkerClustererState(null);
            }

            waitForLoad(
              ".zoom-control-in",
              () => {
                this.createDisplayRadius(this.props.map)
                  .then((returnVal) => this.createMapMarkers(this.props.map, returnVal.validLocations, returnVal.displayRadius, false))
                  .then(() => {
                    this.props.setAddMapListenersState(true);
                  });
              },
              5
            );
          }
        }
    }


    setGoogleLocationData (place) {
        let address = place[0].formatted_address || place[0].name
        let geolocation = place[0].geometry.location
        let lat = geolocation.lat()
        let lng = geolocation.lng()
        updateCookie(null, 'fad_search')
        return {locq: address, lat: lat, lng: lng}
    }

    async searchNewArea(map) {
        if (map === null || map === undefined || !map.getBounds()) {
            return
        }
        const { spherical }  = await google.maps.importLibrary("geometry")
        let newSearchLatLng = map.getCenter()
        let geocoderService = new google.maps.Geocoder()
        let radiusMeters = spherical.computeDistanceBetween(newSearchLatLng, map.getBounds().getNorthEast()) * .97

        let radiusMiles
        if (radiusMeters < 1609.34) {
            radiusMiles = radiusMeters / 1609.34
        } else {
            radiusMiles = Math.floor(radiusMeters / 1609.34)
        }

        let bounds = map.getBounds() 
        this.setState({searchBounds: bounds})

        geocoderService.geocode({ location: {lat: newSearchLatLng.lat(), lng: newSearchLatLng.lng()} },
            (place) => {
              let locq = this.setGoogleLocationData(place)
              let newSearchParams = {
                q: (this.props.urlParameters && this.props.urlParameters.search && this.props.urlParameters.search.q) ? this.props.urlParameters.search.q : '',
                locq: locq.locq,
                lat: locq.lat,
                lng: locq.lng,
                dist: (radiusMiles <= 400) ? radiusMiles : 400
              }
              if (this.props.searchOptions && this.props.searchOptions.enableUrl) {
                if (newSearchParams.q && newSearchParams.q.indexOf('&') > -1) newSearchParams.q = newSearchParams.q.replace('&', encodeURIComponent('&'))
              }
              this.props.runSearch(newSearchParams, bounds)
            })
    }

    getLatLngFromCookie() {
        let latlng = {}
        let existingCookie = Cookies.get('fad_search') ? JSON.parse(Cookies.get('fad_search')) : []
        if (existingCookie.length) {
            existingCookie.map((param) => {
                if (param.name === 'lat') {
                    latlng.lat = param.value
                }
                if (param.name === 'lng') {
                    latlng.lng = param.value
                }
            })
        }
        return latlng
    }

    async initMap() {
        const { Map } = await google.maps.importLibrary("maps")
        const { spherical } = await google.maps.importLibrary("geometry")

        let enableFractionalZoom = window.innerWidth < 770 ? true : false

        const map = new Map(this.mapRef.current, {
            center: this.center,
            zoom: 13,
            disableDefaultUI: true,
            styles: [
                {
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [
                        { visibility: 'off' }
                    ]
                }
            ],
            zoomControl: false,
            gestureHandling: 'greedy',
            isFractionalZoomEnabled: enableFractionalZoom,
        })

        google.maps.event.addListenerOnce(map, 'idle', () => {
            this.addUserMarker(map).then(() => this.createZoomButtons(map))
            this.props.setMapState(map)
        })
    }

    async addUserMarker(map) {
        let geoPermission = window.localStorage.getItem('geoPermission')
        if (navigator.geolocation && geoPermission && geoPermission === 'accepted') {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                    let userMarker = new google.maps.Marker({
                        position: pos,
                        icon: {
                            path: google.maps.SymbolPath.CIRCLE,
                            fillColor: "white",
                            fillOpacity: 1,
                            rotation: 0,
                            scale: 10,
                            strokeColor: "#0070AB",
                            strokeWeight: 4,
                            strokeOpacity: 1,
                        },
                        draggable: false,
                    });
                    new google.maps.Marker({
                        position: pos,
                        icon: {
                            path: google.maps.SymbolPath.CIRCLE,
                            fillColor: "#0070AB",
                            fillOpacity: .35,
                            rotation: 0,
                            scale: 18,
                            strokeWeight: 0,
                            strokeOpacity: 0,
                        },
                        map: map,
                        draggable: false,
                    });
                    userMarker.setMap(map)
                    this.setState({ userLatLng: pos });
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        window.localStorage.setItem('geoPermission', 'false')
                        GeolocationAcceptOrDeny('denied')
                        if(document.querySelector('#recenter-icon')) {
                            document.querySelector('#recenter-icon').setAttribute('class', 'recenter-icon diasbled')
                        }
                    };
                }
            );
        }
    }

    createZoomButtons(map) {
        const zoomMap = (zoomOffset, map) => {
            if (map.getZoom() === 5 && zoomOffset < 0) {
                return
            }
            map.setZoom(map.getZoom() + zoomOffset)
        }

        const zoomControlDiv = document.createElement("div");
        zoomControlDiv.setAttribute("class", "controls zoom-control");

        let zoomInButton = document.createElement("button");
        zoomInButton.setAttribute("class", "zoom-control-in");
        zoomInButton.textContent = "+";
        zoomInButton.addEventListener("click", () => {
            zoomMap(1, map);
        });
        let zoomOutButton = document.createElement("button");
        zoomOutButton.setAttribute("class", "zoom-control-out");
        zoomOutButton.textContent = "–";
        zoomOutButton.addEventListener("click", () => {
            zoomMap(-1, map);
        });

        zoomControlDiv.appendChild(zoomInButton);
        zoomControlDiv.appendChild(zoomOutButton);

        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);
    }

    isMarkerSelectedLocation(markerLoc) {
        if (!this.props.selectedLocation || !markerLoc) {
            return false
        }
        let selAdrKey =  this.props.selectedLocation.AddressKey ?  this.props.selectedLocation.AddressKey : this.props.selectedLocation.id
        let locAdrKey = markerLoc.AddressKey ? markerLoc.AddressKey : markerLoc.id
        if (selAdrKey === locAdrKey) {
            return true
        }
        else {
            return false
        }
        
    }

    async createDisplayRadius(map) {
        let newCenter = {
            lat: this.props.fadSearchedBounds ? this.props.autoSearchedLocation.lat : this.props.urlParameters.search.lat ? parseFloat(this.props.urlParameters.search.lat) : this.center.lat,
            lng: this.props.fadSearchedBounds ? this.props.autoSearchedLocation.lng :this.props.urlParameters.search.lng ? parseFloat(this.props.urlParameters.search.lng) : this.center.lng
        }

        let newRadius = ((this.props.fadSearchedBounds ? this.props.autoSearchedLocation.dist : this.props.urlParameters.search.dist ? parseInt(this.props.urlParameters.search.dist) : 5) * 1609.344)

        let displayRadius
        displayRadius = new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0,
            center: new google.maps.LatLng(newCenter.lat, newCenter.lng),
            radius: newRadius,
        });

        const searchResults = this.getSearchResults();
        if (this.props.flow == "mam") {
          var validLocations = { validOPGLocations: searchResults, validIndependentLocations: searchResults.map((x) => x.addresses) };
        } else {
          var validLocations = await createValidLocationSet(searchResults);
        }

        try {
            if (validLocations.length === 0) {
                throw new Error(" search results list does not have any valid locations ")
            }
        }
        catch (error) {
            console.error(error)
        }
        
        return { displayRadius, validLocations }
    }

    async createMapMarkers(map, validLocations, displayRadius, changeMapView = null) {
        if (map && this.props.zoomChangedListenerRef) {
            google.maps.event.removeListener(this.props.zoomChangedListenerRef)
            google.maps.event.clearListeners(map, 'dragend')
            this.props.setAddMapListenersState(false)
        }

        let locationsWithinRadius = []
        let markers = []
        let displayBounds = new google.maps.LatLngBounds()
        let crnSearchBounds = this.state.searchBounds ? this.state.searchBounds : this.props.fadSearchedBoundsObj

        for (let location of validLocations.validOPGLocations) {
            locationsWithinRadius.push(location)
            const latitude = parseFloat(location.Latitude)
            const longitude = parseFloat(location.Longitude)
            if (changeMapView === false && crnSearchBounds
                && !crnSearchBounds.contains({lat: latitude, lng: longitude})) {
                    continue
                }
            displayBounds.extend(new google.maps.LatLng(latitude, longitude))
            const marker = new google.maps.Marker({
                map: map,
                position: { lat: latitude, lng: longitude },
                icon: defaultMarkerIconPNG
            });

            marker.AddressKey = location.AddressKey||location.id;
            if (this.isMarkerSelectedLocation(location)) {
                marker.setIcon(hoveredMarkerIconPNG)
                this.props.setSelectedProvider(null, null)
                this.props.setSelectedLocation(location, marker)
                isSelectedLocationVisible = true
            }
            markers.push(marker)

            const toolTipContent = this.createToolTipMarkupOPG(location, marker)
            const toolTip = new window.google.maps.InfoWindow({
                maxWidth: 260,
                content: '',
                disableAutoPan: true,
            })

            marker.addListener('mouseover', () => {
                if (this.currentHoveredMarker && !(this.isMarkerSelectedLocation(this.currentHoveredLocation))) this.currentHoveredMarker.setIcon(defaultMarkerIconPNG)
                if (this.currentOpenInfoWindow) {
                    this.currentOpenInfoWindow.close()
                }
                
                const contentElement = document.createElement('div')
                ReactDOM.render(toolTipContent, contentElement)

                toolTip.setContent(contentElement)
                if (window.innerWidth >= 1200) {
                    toolTip.open({
                        anchor: marker,
                        map,
                        shouldFocus: false,
                    })
                }
                marker.setZIndex(Number(google.maps.Marker.MAX_ZINDEX) + 50000)
                marker.setIcon(hoveredMarkerIconPNG)

                this.currentHoveredMarker = marker
                this.currentHoveredLocation = location
                if (window.innerWidth >= 1200) {
                    this.currentOpenInfoWindow = toolTip
                }
                else {
                    this.currentOpenInfoWindow = null
                }
                if (this.closeTimeoutId) {
                    clearTimeout(this.closeTimeoutId)
                    this.closeTimeoutId = null
                }
            })

            marker.addListener('mouseout', () => { 
                marker.setZIndex(Number(google.maps.Marker.MAX_ZINDEX) + 1)
                this.closeTimeoutId = setTimeout(() => {
                    toolTip.close()
                    if(!this.props.selectedLocation || !(this.isMarkerSelectedLocation(location))) marker.setIcon(defaultMarkerIconPNG)
                    this.currentHoveredMarker = null
                    this.currentHoveredLocation = null
                    this.currentOpenInfoWindow = null
                }, 300)
            })

            marker.addListener('click', () => {
                if (!this.props.selectedProvider) {
                    marker.setIcon(hoveredMarkerIconPNG)
                    this.props.setSelectedProvider(null, null)
                    if (this.isMarkerSelectedLocation(location)) {
                        this.props.setSelectedLocation(null, null, 'resetScroll')
                    }
                    else {
                        this.props.setSelectedLocation(location, marker)
                    }
                }
            })

            google.maps.event.addListener(toolTip, 'domready', () => { 
                const infoWindowContent = document.getElementById(`location-${location.Id}`)
                if (infoWindowContent) {
                    infoWindowContent.addEventListener('wheel', (event) => {
                        event.preventDefault()
                        event.stopPropagation()
                    }, {passive: false})
                    infoWindowContent.addEventListener('mouseover', () => {
                        if (this.closeTimeoutId) {
                            clearTimeout(this.closeTimeoutId)
                            this.closeTimeoutId = null
                        }
                    })

                    infoWindowContent.addEventListener('mouseout', () => {
                        this.closeTimeoutId = setTimeout(() => {
                            if (this.currentOpenInfoWindow) {
                                this.currentOpenInfoWindow.close()
                                this.currentOpenInfoWindow = null
                                if(!this.props.selectedLocation || !(this.isMarkerSelectedLocation(location))) marker.setIcon(defaultMarkerIconPNG)
                                
                                this.currentHoveredMarker = null
                                this.currentHoveredLocation = null
                            }
                        }, 300)
                    })
                }
            })
        }

        for (let location of validLocations.validIndependentLocations) {
            locationsWithinRadius.push(location)
            const latitude = parseFloat(location.lat || location.Latitude)
            const longitude = parseFloat(location.lng || location.Longitude)
            if (changeMapView === false && crnSearchBounds
                && !crnSearchBounds.contains({lat: latitude, lng: longitude})) {
                    continue
                }
            displayBounds.extend(new google.maps.LatLng(latitude, longitude))
            const marker = new google.maps.Marker({
                map: map,
                position: { lat: latitude, lng: longitude },
                icon: defaultMarkerIconPNG
            });

            marker.AddressKey = location.id
            if (this.isMarkerSelectedLocation(location)) {
                marker.setIcon(hoveredMarkerIconPNG)
                this.props.setSelectedProvider(null, null)
                this.props.setSelectedLocation(location, marker)
                isSelectedLocationVisible = true
            }
            markers.push(marker)

            const toolTipContent = this.createToolTipMarkupIndependent(location, marker)

            const toolTip = new window.google.maps.InfoWindow({
                maxWidth: 260,
                content: ``,
                disableAutoPan: true,
            })

            marker.addListener('mouseover', () => {
                if (this.currentHoveredMarker && !(this.isMarkerSelectedLocation(this.currentHoveredLocation))) this.currentHoveredMarker.setIcon(defaultMarkerIconPNG)
                if (this.currentOpenInfoWindow) {
                    this.currentOpenInfoWindow.close()
                }
                const contentElement = document.createElement('div')
                ReactDOM.render(toolTipContent, contentElement)

                toolTip.setContent(contentElement)
                if (window.innerWidth >= 1200) {
                    toolTip.open({
                        anchor: marker,
                        map,
                        shouldFocus: false,
                    })
                }
                marker.setZIndex(Number(google.maps.Marker.MAX_ZINDEX) + 50000)
                marker.setIcon(hoveredMarkerIconPNG)

                this.currentHoveredMarker = marker
                this.currentHoveredLocation = location
                if (window.innerWidth >= 1200) {
                    this.currentOpenInfoWindow = toolTip
                }
                else {
                    this.currentOpenInfoWindow = null
                }
                if (this.closeTimeoutId) {
                    clearTimeout(this.closeTimeoutId)
                    this.closeTimeoutId = null
                }
            })

            marker.addListener('mouseout', () => {
                marker.setZIndex(Number(google.maps.Marker.MAX_ZINDEX) + 1)
                this.closeTimeoutId = setTimeout(() => {
                    toolTip.close()
                    if(!this.props.selectedLocation || !(this.isMarkerSelectedLocation(location))) marker.setIcon(defaultMarkerIconPNG)
                    this.currentHoveredMarker = null
                    this.currentHoveredLocation = null
                    this.currentOpenInfoWindow = null
                }, 300)
            })

            marker.addListener('click', () => {
                if (!this.props.selectedProvider) {
                    marker.setIcon(hoveredMarkerIconPNG)
                    this.props.setSelectedProvider(null, null)
                    if (this.isMarkerSelectedLocation(location)) {
                        this.props.setSelectedLocation(null, null, 'resetScroll')
                    }
                    else {
                        this.props.setSelectedLocation(location, marker)
                    }
                }
            })

            google.maps.event.addListener(toolTip, 'domready', () => { 
                const infoWindowContent = document.getElementById(`location-${location.id}`)
                if (infoWindowContent) {
                    infoWindowContent.addEventListener('wheel', (event) => {
                        event.preventDefault()
                        event.stopPropagation()
                    }, {passive: false})
                    infoWindowContent.addEventListener('mouseover', () => {
                        if (this.closeTimeoutId) {
                            clearTimeout(this.closeTimeoutId)
                            this.closeTimeoutId = null

                        }
                    })

                    infoWindowContent.addEventListener('mouseout', () => {
                        this.closeTimeoutId = setTimeout(() => {
                            if (this.currentOpenInfoWindow) {
                                this.currentOpenInfoWindow.close()
                                this.currentOpenInfoWindow = null
                                if(!this.props.selectedLocation || !(this.isMarkerSelectedLocation(location))) marker.setIcon(defaultMarkerIconPNG)
                                this.currentHoveredMarker = null
                                this.currentHoveredLocation = null
                            }
                        }, 300)
                    })
                }
            })
        }


        const { spherical } = await google.maps.importLibrary("geometry")
        let newCenter = {
            lat: parseFloat(this.center.lat),
            lng: parseFloat(this.center.lng)
        }
           
        if (this.props.urlParameters.search.locq !== undefined) {
            newCenter = {
                lat: parseFloat(this.props.fadSearchedBounds ? this.props.autoSearchedLocation.lat : this.props.urlParameters.search.lat),
                lng: parseFloat(this.props.fadSearchedBounds ? this.props.autoSearchedLocation.lng : this.props.urlParameters.search.lng)
            }
        }

        displayBounds.extend(new google.maps.LatLng(newCenter.lat, newCenter.lng))
        if (this.props.urlParameters.search.locq !== undefined || window.innerwidth >= 1200) {
            displayBounds.extend(spherical.computeOffset(displayRadius.getCenter(), displayRadius.getRadius(), -90))
            displayBounds.extend(spherical.computeOffset(displayRadius.getCenter(), displayRadius.getRadius(), 90))
        }
        
        if ((this.state.searchBounds === null && !this.props.fadSearchedBounds) && this.props.selectedLocation === null) {
            if (window.innerWidth < 1200) {
                map.fitBounds(displayBounds, {top: 50, bottom: 10, right: 10, left: 10})
            }
            else {
                map.fitBounds(displayBounds)
            }
            if (this.props.urlParameters.search.locq !== undefined || window.innerwidth >= 1200) {
                map.setCenter(displayRadius.getCenter())
            }
            else if (this.props.urlParameters.search.locq === undefined && window.innerwidth < 770){
                map.setCenter(displayBounds.getCenter())
            }

        }

        this.setState({
            mapMarkerLocations: locationsWithinRadius,
            displayRadius: displayRadius,
            displayBounds: displayBounds,
            searchBounds: null
        })

        markers.forEach(marker => {
            marker.setZIndex(Number(google.maps.Marker.MAX_ZINDEX) + 1)
        })
        this.props.setMarkersState(markers)
      
        let customClusterIconRenderer = this.customClusterIconRenderer()
        let clusterRadius = window.innerWidth < 770 ? 130 : 150
        const modifiedAlgorithm = new SuperClusterAlgorithm({ radius: clusterRadius, maxZoom: 17, minPoints: 8,})
        const markerClusterer = new MarkerClusterer({ 
            algorithm: modifiedAlgorithm,
            map, 
            markers,
            renderer: customClusterIconRenderer,
            onClusterClick: () => {}
        });
        let newClustList = google.maps.event.addListener(markerClusterer, 'click', (cluster) => {
            if (map.getZoom() <= 10) {
                map.setZoom(map.getZoom() + 2)
                map.setCenter(cluster._position)
            }
            else {
                map.fitBounds(cluster.bounds)
            }
        });
        if (this.props.markerClustererFAD !== null) {
            this.props.markerClustererFAD.clearMarkers()
            google.maps.event.removeListener(this.markClusterListener)
            this.props.setMarkerClustererState(null)
        }
        this.props.setMarkerClustererState(markerClusterer)
        this.markClusterListener = newClustList
      

        return locationsWithinRadius
    }

    async centerUserLocation(map) {
        if (map === null) {
            return
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => { 
                    let pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    if (this.state.userLatLng === null) {
                        let userMarker = new google.maps.Marker({
                            position: pos,
                            icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: "white",
                                fillOpacity: 1,
                                rotation: 0,
                                scale: 10,
                                strokeColor: "#0070AB",
                                strokeWeight: 4,
                                strokeOpacity: 1,
                            },
                            draggable: false,
                        });
                        new google.maps.Marker({
                            position: pos,
                            icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: "#0070AB",
                                fillOpacity: .35,
                                rotation: 0,
                                scale: 18,
                                strokeWeight: 0,
                                strokeOpacity: 0,
                            },
                            map: map,
                            draggable: false,
                        });
                        userMarker.setMap(map)
                    }
                    this.setState({ userLatLng: pos });
                    map.panTo(pos)
                    
                    if (!this.props.selectedProvider) {
                        google.maps.event.addListenerOnce(map, 'idle', () => {
                            setTimeout(() => {
                                this.searchNewArea(map)
                            }, 200)
                        })
                    }
                    GeolocationAcceptOrDeny('accepted')
                    if(document.querySelector('#recenter-icon')) {
                        document.querySelector('#recenter-icon').setAttribute('class', 'recenter-icon abled')
                    }
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        window.localStorage.setItem('geoPermission', 'false')
                        GeolocationAcceptOrDeny('denied')
                        if(document.querySelector('#recenter-icon')) {
                            document.querySelector('#recenter-icon').setAttribute('class', 'recenter-icon diasbled')
                        }
                    };
                }
            );
        }
    }

    createToolTipMarkupOPG(location, marker) {
        const onTitleClick = () => {
            marker.setIcon(hoveredMarkerIconPNG)
            this.props.setSelectedProvider(null, null)
            this.props.setSelectedLocation(location, marker)
        }
        const addressBuilder = (address) => {
            return {
                street: address.AddressLine1,
                cityStateZip: `${address.City}, ${address.State} ${address.ZipCode}`
            }
        }
        const locationId = `location-${location.Id}`
        return (
            <div id={locationId} class="tool-tip-container">
                <div class='tool-tip-img-wrapper'>
                    {this.props.flow ==='mam' ? (
                        location.ImageUrl != null ? (
                          <img class='tool-tip-img' src={`${location.ImageUrl}`} alt={location.SearchNameOverride} aria-label={location.SearchNameOverride} />
                    ): (                       
                        <img class='tool-tip-img' src={`https://www.ohiohealth.com/${URLS.defaultLocationImage}`} alt={"default location image"} aria-label={location.SearchNameOverride} />
                    )
                    ) : (
                        location.ImageUrl != null ? (
                            <img class='tool-tip-img' src={`https://www.ohiohealth.com/${location.ImageUrl}`} alt={location.SearchNameOverride} aria-label={location.SearchNameOverride} />
                    ) : (                    
                        <img class='tool-tip-img' src={`https://www.ohiohealth.com/${URLS.defaultLocationImage}`} alt={"default location image"} aria-label={location.SearchNameOverride} />
                    ))}
                </div>

                <div class="tool-tip-info" >
                    <div class="tool-tip-name"  onclick={onTitleClick}> {location.Name}</div>
                    <div class="tool-tip-phone" > {location.AddressLine1} {location.City}, {location.State}</div>
                    {/* <div class="tool-tip-address"  onclick={onTitleClick}> {location.AddressLine1} {location.City}, {location.State}</div> */}
                    {
                        location.customDistance ?
                            <div class="tool-tip-address-line-2">{location.customDistance} mi {location.AddressLine2 && <span> - {location.AddressLine2}</span>} </div> :
                            <div class="tool-tip-address-line-2">{location.AddressLine2 && <span>{location.AddressLine2}</span>}</div>
                    }
                    {
                        location.AddressLine1 &&
                        <div class="tool-tip-directions">
                            <DirectionsLink address={addressBuilder(location)} text='Directions' displayIcon={false} />
                        </div>
                    }
                    {location.Phone &&
                        <div class="tool-tip-phone">{location.Phone}</div>
                    }
                </div>
            </div>
        )
    }

    createToolTipMarkupIndependent(location, marker) {
        const onTitleClick = () => {
            marker.setIcon(hoveredMarkerIconPNG)
            this.props.setSelectedProvider(null, null)
            this.props.setSelectedLocation(location, marker)
        }
        const addressBuilder = (address) => {
            return {
                street: address.adr,
                cityStateZip: `${address.c}, ${address.s} ${address.z}`
            }
        }
        const handleGetPhoneNumber = (address) => {
            const phone = getPhoneNumber(address)
            return (
                <React.Fragment>
                    {phone && phone.Number !== '' && <div class="tool-tip-phone">{phone.Number}</div>}
                </React.Fragment>
            )
        }
        const locationId = `location-${location.id}`
        return (
            <div id={locationId} class="tool-tip-container">
                <div class='tool-tip-img-wrapper'>
                    <img class='tool-tip-img' src={`https://www.ohiohealth.com/${URLS.defaultLocationImage}`} alt="default location image" aria-label={location.SearchNameOverride} />
                </div>
                <div class="tool-tip-info" >

                    <div class="tool-tip-address"  onclick={onTitleClick}>{location.adr} {location.c}, {location.s}</div>

                    {
                        location.d &&
                        <div class="tool-tip-address-line-2">{location.d} mi </div>
                    }
                    {
                        location.adr &&
                        <div class="tool-tip-directions">
                            <DirectionsLink address={addressBuilder(location)} text='Directions' displayIcon={false} />
                        </div>
                    }
                    {handleGetPhoneNumber(location)}
                </div>
            </div>
        )
    }

    getSearchResults(){
        if (this.props.flow && this.props.searchResults) {
          if (this.props.flow != "mam") {
            return this.props.searchResults;
          } else {
            return this.props.searchResults.map((x) => ({
              SearchNameOverride: x.Name,
              Name: x.Name,
              AddressLine1: x.Address,
              AddressLine2: null,
              AddressKey: x.Id + "",
              ImageUrl: x.LandscapePhotoURL,
              SmallHeaderImageUrl: x.LandscapePhotoURL,
              addresses: [
                {
                  id: x.Id + "",
                  dpt: null,
                  lbl: x.Name,
                  adr: x.Address,
                  c: x.City,
                  s: x.State,
                  z: x.ZipCode,
                  ao: null,
                  lat: x.Latitude,
                  lng: x.Longitude,
                  prim: true,
                  cr: [
                    {
                      type: "Phone",
                      Number: x.Phone,
                    },
                    {
                      type: "Fax",
                      Number: x.Phone,
                    },
                  ],
                  h: null,
                  d: null,
                  url: null,
                  wt: null,
                },
              ],
              ...x,
            }));
          }
        }
    }

    customClusterIconRenderer() {
        const customClusterIconRenderer = {
            render: (cluster, stats, map) => {
                const count = cluster.count
                const position = cluster.position
                let icons
                let addressKeysInCluster = []
                let providersWithinCluster = []

                
                for (let marker of cluster.markers) {
                    let addressKeys = marker.AddressKey.split(/,\s*/)
                    addressKeys.forEach(key => {
                        addressKeysInCluster.push(key.trim())
                    })
                }

                const searchResults = this.getSearchResults();
                for (let provider of searchResults) {
                    for (let address of provider.addresses) {
                        if (addressKeysInCluster.includes(address.id)) {
                            providersWithinCluster.push(provider)
                            break
                        }
                    }
                }

                if (window.innerWidth >= 1200) {
                    icons = {
                        underFiftyProviders: {
                            url: '/ClientResources/Website/images/fadmap/cluster-base-xsmall.png',
                            scaledSize: new google.maps.Size(40, 40),
                        },
                        underFiveHundredProviders: {
                            url: '/ClientResources/Website/images/fadmap/cluster-base-small.png',
                            scaledSize: new google.maps.Size(56, 56),
                        },
                        overFiveHundredProviders: {
                            url: '/ClientResources/Website/images/fadmap/cluster-base.png',
                            scaledSize: new google.maps.Size(80, 80),
                        },
                    }
                }
                else {
                    icons = {
                        underFiftyProviders: {
                            url: '/ClientResources/Website/images/fadmap/cluster-base-xsmall.png',
                            scaledSize: new google.maps.Size(39, 39),
                        },
                        underFiveHundredProviders: {
                            url: '/ClientResources/Website/images/fadmap/cluster-base-small.png',
                            scaledSize: new google.maps.Size(47, 47),
                        },
                        overFiveHundredProviders: {
                            url: '/ClientResources/Website/images/fadmap/cluster-base.png',
                            scaledSize: new google.maps.Size(55, 55),
                        },
                    }
                }
                    

                let icon
                let fontSize

                if (providersWithinCluster.length < 50) {
                    icon = icons.underFiftyProviders
                    fontSize = (window.innerWidth < 1200) ? '14px' : '16px'
                } else if (providersWithinCluster.length < 500) {
                    icon = icons.underFiveHundredProviders
                    fontSize = (window.innerWidth < 1200) ? '15px' : '20px'
                } else {
                    icon = icons.overFiveHundredProviders
                    fontSize = (window.innerWidth < 1200) ? '16px' : '26px'
                }

                const zIndex = Number(google.maps.Marker.MAX_ZINDEX) + count;
    
                const clusterOptions = {
                    position: position,
                    icon: icon,
                    zIndex: zIndex,
                    label: {
                        text: String(providersWithinCluster.length),
                        color: 'white',
                        fontSize: fontSize,
                    }
                };
                return new google.maps.Marker(clusterOptions);
            }
        }
        return customClusterIconRenderer
    }

    render() {
        let geoPermission = window.localStorage.getItem('geoPermission')
        return (
            <React.Fragment>
                <div id='map' ref={this.mapRef} className='map-super-results'>

                </div>
                <div class='recenter-icon-div'>
                    <SVGInline id='recenter-icon' className={`recenter-icon ${(geoPermission && geoPermission === 'accepted') ? 'abled' : 'disabled'}`} onClick={() => this.centerUserLocation(this.props.map)} svg={locIcon} />
                </div>
            </React.Fragment>
        )
    }

}